import * as React from "react"
import Layout from "../components/layout"
import {Link} from "gatsby"
import {StaticImage} from "gatsby-plugin-image"
import BrandedDivider from "../components/branded_divider"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import {
  cardTitle,
  cardContent
} from "../css/index.module.css"
import PageBanner from "../components/page_banner"
import {SEO} from "../components/seo"
import EnginuityGcLink from "../components/enginuity_gc_link"

const ServicesPage = () => {
  return (
    <Layout pageTitle="Services">
      <PageBanner title="every detail counts">
        <StaticImage
          alt="EnginuityUSA - Services"
          src="../images/services.jpg"
          objectFit="cover"
        />
      </PageBanner>

      <Container className="py-5">
        <Row className="py-3">
          <Col>
            <h4 className={cardTitle}><BrandedDivider/>A FULL-RANGE OF ENGINEERING & CONSTRUCTION SERVICES</h4>

            <p className={cardContent}>Enginuity Consulting Engineers, LLC puts a
              great deal of time and effort into every project. Whether it's a small restaurant, or a multi
              million dollar high rise building, our level of
              commitment and attention to detail remains
              the same.
            </p>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <h4 className={cardTitle}><BrandedDivider/>MEP DESIGN</h4>

            <p className={cardContent}>Practical engineering is our daily goal for each client's project, be it HVAC
              design, Power and
              Lighting or Water, Sanitary and Fire Protection.
            </p>
            <p className={cardContent}>
              We strive to seek out the most cost-effective design that meets the most demanding design
              criteria.
            </p>
            <p className={cardContent}>
              MEP design work in demanding markets like the Pharmaceutical Industry, Hospitals,
              Manufacturing and many others have enabled us to use the collective exposure from
              completing MEP design work on a variety of project types to proffer our clients a truly
              practical solution to their design challenges.
              We utilize AutoCAD and REVIT softwares depending on the project and client needs.
            </p>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <h4 className={cardTitle}><BrandedDivider/>CONSTRUCTION</h4>

            <p className={cardContent}>With our sister company, <EnginuityGcLink/>, we can provide a true
              design-build package to our clients. With the ability to provide design services "in-house", this
              enables us to better control costs and allows us to immediately respond to any issues that
              may arise in the field. This gives us a huge advantage over our competition. Our Project
              Managers have over 40 years of Professional Design and Project Management experience in
              Commerical, Residential and Industrial projects.
            </p>
          </Col>
        </Row>
        <Row className="py-3">
          <Col>
            <h4 className={cardTitle}><BrandedDivider/>AN EXTENSIVE PORTFOLIO</h4>

            <p className={cardContent}>
              If a picture is worth a thousand words, our <Link to="/projects">portfolio</Link> is a fantastic novel.
              You are
              welcome to browse
              over some of our completed projects, getting a sense of our commitment to detail and design, as well as
              pulling inspiration for your own home or project. While no two projects are ever the same, it is always
              good to see what is possible and what might spark an idea.
            </p>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}


export const Head = () => <SEO title={"Enginuity USA | Services"}/>

export default ServicesPage
